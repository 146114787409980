import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';

const BlogHeader = ({ title, link, iconClass, buttonText }) => {
  const navigate = useNavigate();
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        {buttonText && (
          <Button onClick={() => navigate(link)} variant="contained" startIcon={<Iconify icon={iconClass} />}>
            {buttonText}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default BlogHeader;
