import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../utils/constant';

export const projectImagesApi = createApi({
  reducerPath: 'projectImages',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/project-images`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Token ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['project-images'],
  endpoints: (builder) => ({
    getProjectImages: builder.query({
      query: (id) => {
        return {
          url: `/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['project-images'],
    }),
    getProjectImagesDetail: builder.query({
      query: (id) => `detail/${id}`,
      providesTags: ['project-images'],
    }),
    addProjectImages: builder.mutation({
      query: (newItem) => {
        return {
          url: '/create/',
          method: 'POST',
          body: newItem,
        };
      },
      invalidatesTags: ['project-images'],
    }),
    updateProjectImages: builder.mutation({
      query: ({ form, id }) => ({
        url: `update/${id}/`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: ['project-images'],
    }),
    deleteProjectImages: builder.mutation({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project-images'],
    }),
  }),
});

export const {
 useGetProjectImagesQuery,
  useGetProjectImagesDetailQuery,
  useAddProjectImagesMutation,
  useUpdateProjectImagesMutation,
  useDeleteProjectImagesMutation,
} = projectImagesApi;

