import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../utils/constant';

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/tags`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Token ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['tags'],
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => {
        return {
          url: '/',
          method: 'GET',
        };
      },
      providesTags: ['tags'],
    }),
    getTag: builder.query({
      query: (id) => `detail/${id}`,
      providesTags: ['tags'],
    }),
    addTag: builder.mutation({
      query: (newItem) => {
        return {
          url: '/create/',
          method: 'POST',
          body: newItem,
        };
      },
      invalidatesTags: ['tags'],
    }),
    updateTag: builder.mutation({
      query: ({ form, id }) => ({
        url: `update/${id}/`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: ['tags'],
    }),
    deleteTag: builder.mutation({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['tags'],
    }),
  }),
});

export const {
    useGetTagsQuery,
    useGetTagQuery,
    useAddTagMutation,
    useUpdateTagMutation,
    useDeleteTagMutation,
} = tagsApi;
