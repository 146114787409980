import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Typography, CardContent, CardActionArea } from '@mui/material';

import Iconify from '../../components/Iconify';
import { WEBSITE_URL } from 'src/utils/constant';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 'calc(100% * 4 / 3)',
  },
  overflow: 'hidden',
}));

const TitleStyle = styled(Link)(({ theme }) => ({
  minHeight: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.text.primary,
  transition: 'color 0.3s ease-in-out',
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

// ----------------------------------------------------------------------

export default function PojectCard({ project, setOpen, setDeletedItem }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 3,
          borderRadius: '16px',
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            boxShadow: 6,
            transform: 'translateY(-5px)',
          },
        }}
      >
        <CardActionArea component={RouterLink} to="#">
          <CardMediaStyle>
            <CoverImgStyle alt={project?.title} src={WEBSITE_URL + project?.cover} />
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '50%',
                p: 1,
                cursor: 'pointer',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9,
              }}
              component={RouterLink}
              to={`/projects/${project?.id}`}
            >
              <Iconify icon="eva:edit-fill" sx={{ width: 25, height: 25, color: 'primary.main' }} />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 66,
                bgcolor: 'error.main',
                borderRadius: '50%',
                p: 1,
                cursor: 'pointer',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9,
              }}
              onClick={() => {
                setDeletedItem(project);
                setOpen(true);
              }}
            >
              <Iconify icon="eva:trash-2-fill" sx={{ width: 25, height: 25, color: '#fff' }} />
            </Box>
          </CardMediaStyle>

          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              p: 3,
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05),
              transition: 'background-color 0.3s ease-in-out',
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            <TitleStyle variant="h6" underline="none" component={RouterLink} to={`/projects/${project?.id}`}>

              {project?.title.length > 15 ? `${project?.title.slice(0, 15)} ....` : project?.title}
            </TitleStyle>

            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {project?.description.length > 30 ? `${project?.description.slice(0, 30)} ....` : project?.description}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Iconify icon="eva:arrow-ios-forward-fill" sx={{ width: 16, height: 16, color: 'primary.main' }} />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
