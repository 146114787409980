import React from 'react';
import ProjectHeader from './ProjectHeader';
import ProjectForm from './ProjectForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectQuery, useUpdateProjectMutation } from 'src/store/projects';
import toast from 'react-hot-toast';
import Loader from 'src/components/Loader';
import {
  useAddProjectImagesMutation,
  useDeleteProjectImagesMutation,
  useGetProjectImagesQuery,
  useUpdateProjectImagesMutation,
} from 'src/store/projects-images';

const EditProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetProjectQuery(id);
  const { data: images, isLoading: isLoadingImages } = useGetProjectImagesQuery(id);
  console.log(images);
  const [editProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const [addProjectImages] = useAddProjectImagesMutation();
  const [deleteProjectImages] = useDeleteProjectImagesMutation();
  const [isPending, setIsPending] = React.useState(false);
  const handleEditProject = async (formData) => {
    setIsPending(true);
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key !== 'images') {
          form.append(key, formData[key]);
        }
      });
      const response = await editProject({ form: form, id: formData?.id }).unwrap();
      await deleteProjectImages(formData?.id).unwrap();
      const images = new FormData();

      images.append('project', response.id);

      for (const image of formData.images) {
        images.set('image', image);
        try {
          await addProjectImages(images).unwrap();
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      }

      toast.success('Successfully Updated!');
      navigate('/projects', { replace: true });
    } catch (error) {
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error('An error occurred. Please try again.');
      }
      console.error('Error updating project:', error);
    } finally {
      setIsPending(false);
    }
  };
  return (
    <>
      <ProjectHeader
        title={'Edit Project'}
        link={'/projects'}
        buttonText={'Back'}
        iconClass={'eva:arrow-ios-back-fill'}
      />
      {isLoading || isLoadingImages ? (
        <Loader />
      ) : (
        <ProjectForm
          isLoading={isPending}
          formTitle={'Edit Project'}
          formID={'edit-project'}
          onSubmit={handleEditProject}
          initialData={{ ...data, images: images?.map((image) => image?.image) }}
        />
      )}
    </>
  );
};

export default EditProject;
