import React, { useState } from 'react';
import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import toast from 'react-hot-toast';
import BlogHeader from './BlogHeader';
import { useAddBlogMutation } from 'src/store/blog';
import { useNavigate } from 'react-router-dom';
import { useGetTagsQuery } from 'src/store/tags';
import { date } from 'yup';
const AddBlog = () => {
  const { quill, quillRef } = useQuill();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [addBlog, { isLoading }] = useAddBlogMutation();
  const { data: availableTags } = useGetTagsQuery();
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  const handleAdd = async () => {
    if (content && title && selectedTags.length > 0) {
      try {
        // const formData = new FormData();
        // formData.append('title', title);
        // formData.append('body', content);
        // formData.append('date', new Date().toISOString().split('T')[0]);
        // formData.append('tags', JSON.stringify(selectedTags));
        console.log(selectedTags);
        const response = await addBlog({
          title,
          date: new Date().toISOString().split('T')[0],
          body: content,
          tags: selectedTags,
        }).unwrap();
        toast.success('Blog added successfully');
        setTitle('');
        setContent('');
        setSelectedTags([]);
        navigate(-1);
      } catch (error) {
        if (error.data) {
          Object.keys(error.data).forEach((key) => {
            toast.error(`${key}: ${error.data[key].join(', ')}`);
          });
        } else {
          toast.error("This didn't work!");
        }
        console.error('Error submitting form:', error);
      }
    } else {
      if (!content) toast.error('Please add content');
      if (!title) toast.error('Please add title');
      if (!selectedTags.length > 0) toast.error('Please add tags');
    }
  };
  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        const content = quill.root.innerHTML;
        setContent(content);
      });
    }
  }, [quill]);
  return (
    <>
      <BlogHeader title={'Add Blog'} link={'/blog'} buttonText={'Back'} iconClass={'eva:arrow-ios-back-fill'} />
      <TextField
        fullWidth
        label="Title"
        sx={{
          '.css-6c8syq-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(145, 158, 171, 0.32)',
          },
        }}
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="tags-label">Tags</InputLabel>
        <Select
          labelId="tags-label"
          id="tags-select"
          multiple
          value={selectedTags}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            setSelectedTags(typeof value === 'string' ? value.split(',') : value);
          }}
          input={<OutlinedInput label="Tags" />}
          renderValue={(selected) =>
            selected
              .map((id) => availableTags.find((tag) => tag.id == id)?.name)
              .filter(Boolean)
              .join(', ')
          }
        >
          {availableTags?.map((tag) => (
            <MenuItem key={`${tag?.id}`} value={`${tag?.id}`}>
              <Checkbox checked={selectedTags.indexOf(`${tag?.id}`) > -1} />
              <ListItemText primary={tag?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ width: '100%', height: '100%', mt: 3 }}>
        <div ref={quillRef} />
      </Box>
      {content && (
        <Box sx={{ mt: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            className="ql-editor"
          ></div>
        </Box>
      )}
      <Box
        sx={{
          mt: 3,
          textAlign: 'right',
        }}
      >
        <LoadingButton
          loading={isLoading}
          onClick={handleAdd}
          size="large"
          type="submit"
          variant="contained"
          color="primary"
        >
          Add Blog
        </LoadingButton>
      </Box>
    </>
  );
};

export default AddBlog;
