import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { projectsApi } from './projects';
import { projectImagesApi } from './projects-images';
import { blogApi } from './blog';
import { tagsApi } from './tags';
import userReducer, { logout } from './login';


export const store = configureStore({
  reducer: {
    
    [projectsApi.reducerPath]: projectsApi.reducer,
    [projectImagesApi.reducerPath]: projectImagesApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    // other reducers

    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      projectsApi.middleware,
      projectImagesApi.middleware,
      blogApi.middleware,
      tagsApi.middleware,
      (store) => (next) => (action) => {
        if (action.error && action.payload && action.payload.status === 401) {
          // Handle 401 Unauthorized error
          console.log('Unauthorized access detected');
          // You can dispatch an action to clear user session or redirect to login page
          store.dispatch(logout());
          // or
          window.location.href = '/login';
        }
        return next(action);
      }
    ),
});

setupListeners(store.dispatch);
