import React, { useState } from 'react';
import ProjectHeader from './ProjectHeader';
import ProjectForm from './ProjectForm';
import { useNavigate } from 'react-router-dom';
import { useAddProjectMutation } from 'src/store/projects';
import toast from 'react-hot-toast';
import { useAddProjectImagesMutation } from 'src/store/projects-images';

const AddProject = () => {
  const navigate = useNavigate();
  const [addProject, { isLoading }] = useAddProjectMutation();
  const [addProjectImages, { isLoading: isLoadingImages }] = useAddProjectImagesMutation();
  const [isPending, setIsPending] = useState(false);

  const handleAddProject = async (formData) => {
    setIsPending(true);
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== 'images') {
          form.append(key, formData[key]);
        }
      });
      const response = await addProject(form).unwrap();

      const images = new FormData();

      images.append('project', response.id);

      for (const image of formData.images) {
        images.set('image', image);
        try {
          await addProjectImages(images).unwrap();
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      }

      toast.success('Successfully Added!');
      navigate('/projects', { replace: true });
    } catch (error) {
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error("This didn't work!");
      }
      console.error('Error submitting form:', error);
    } finally {
      setIsPending(false);
    }
  };
  return (
    <>
      <ProjectHeader
        title={'Add Project'}
        link={'/projects'}
        buttonText={'Back'}
        iconClass={'eva:arrow-ios-back-fill'}
      />
      <ProjectForm
        isLoading={isPending}
        formTitle={'Add Project'}
        formID={'add-project'}
        onSubmit={handleAddProject}
      />
    </>
  );
};

export default AddProject;
