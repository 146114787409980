import React, { useState } from 'react';

import Loader from 'src/components/Loader';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  colors,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ProjectsSearch from '../Gallry/ProjectsSearch';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
// import DeleteBlog from './DeleteBlog';
import { useNavigate } from 'react-router-dom';
import BlogHeader from '../Blog/BlogHeader';
import { useAddTagMutation, useGetTagsQuery } from 'src/store/tags';
import DeleteTag from './DeleteTag';
import toast from 'react-hot-toast';

const TagsHome = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, isLoading } = useGetTagsQuery();
  const [open, setOpen] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [addTag, { isLoading: isAddingTagLoading }] = useAddTagMutation();
  const navigate = useNavigate();

  const handleSearch = (event) => {
    setSearchTerm(event);
  };
  const handleDelete = async (item) => {
    setOpen(true);
    setDeletedItem(item);
  };
  const handleAddTag = async (tag) => {
    const formData = new FormData();
    formData.append('name', tag);
    if (tag.trim() == '') {
      toast.error('Tag name cannot be empty');
      return;
    }
    try {
      await addTag(formData).unwrap();
      toast.success('Tag added successfully');
      setIsAddingTag(false);
    } catch (error) {
      toast.error('Failed to add tag');
    }
  };
  return (
    <Container maxWidth="xl">
      <BlogHeader title={'Tags'} link={'/tags/add'} iconClass={'eva:plus-fill'} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <Button
          variant="contained"
          startIcon={<Iconify icon={isAddingTag ? 'eva:close-outline' : 'eva:plus-outline'} />}
          onClick={() => setIsAddingTag(!isAddingTag)}
        >
          {isAddingTag ? 'Cancel' : 'Add Tag'}
        </Button>
      </Box>
      {isAddingTag && (
        <form
          style={{ marginBottom: '20px' }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAddTag(e.target.elements.newTag.value);
          }}
        >
          <TextField
            fullWidth
            label="New Tag"
            variant="outlined"
            name="newTag"
            // value={newTag}
            // onChange={(e) => 'test'}
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" color="primary" disabled={isAddingTagLoading}>
            Submit
          </Button>
        </form>
      )}
      {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
        <ProjectsSearch projects={data} value={searchTerm} onChange={handleSearch} />
      </Stack> */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            {data?.map((tag) => (
              <Chip
                label={tag.name}
                key={tag.id}
                onDelete={() => handleDelete(tag)}
                deleteIcon={<Iconify icon="eva:trash-2-outline" sx={{ color: `#ff3a3a !important` }} />}
                sx={{
                  padding: '15px 8px',
                  margin: '4px',
                  fontSize: '16px',
                  fontWeight: 'medium',
                  backgroundColor: '#3f51b5',
                  minHeight: '40px',
                  borderRadius: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#232323',
                  },
                }}
              />
            ))}
          </Box>
        </>
        // <Grid container spacing={3}>
        //   {filteredBlog && filteredBlog.length > 0 ? (
        //     filteredBlog.map((blog) => (
        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         key={blog.id}
        //         sx={{
        //           cursor: 'pointer',
        //         }}
        //       >
        //         <Card>
        //           <Box sx={{ pt: '50px', position: 'relative' }}>
        //             <Label
        //               variant="filled"
        //               color={'info'}
        //               sx={{
        //                 zIndex: 9,
        //                 top: 10,
        //                 right: 10,
        //                 position: 'absolute',
        //                 textTransform: 'uppercase',
        //                 py: 3,
        //                 px: 1,
        //                 cursor: 'pointer',
        //               }}
        //               onClick={() => navigate(`/blog/${blog?.id}`)}
        //             >
        //               <Iconify icon={'eva:edit-outline'} sx={{ width: 24, height: 24 }} />
        //             </Label>
        //             <Label
        //               variant="filled"
        //               color={'error'}
        //               sx={{
        //                 zIndex: 9,
        //                 top: 10,
        //                 right: 60,
        //                 position: 'absolute',
        //                 textTransform: 'uppercase',
        //                 py: 3,
        //                 px: 1,
        //                 cursor: 'pointer',
        //               }}
        //               onClick={() => {
        //                 setDeletedItem(blog);
        //                 setOpen(true);
        //               }}
        //             >
        //               <Iconify icon={'eva:trash-2-fill'} sx={{ width: 24, height: 24 }} />
        //             </Label>
        //           </Box>
        //           <CardContent>
        //             <Typography variant="h5" component="h3">
        //               {blog?.title}
        //             </Typography>
        //             <Typography color="textSecondary">{new Date(blog?.date).toLocaleDateString()}</Typography>
        //           </CardContent>
        //         </Card>
        //       </Grid>
        //     ))
        //   ) : (
        //     <Typography variant="h5" component="h3" sx={{ px: 3 }}>
        //       No Blogs Found
        //     </Typography>
        //   )}
        // </Grid>
      )}

      {open && <DeleteTag open={open} setOpen={setOpen} item={deletedItem} setDeletedItem={setDeletedItem} />}
    </Container>
  );
};

export default TagsHome;
